import React, {useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
//import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import userpool from "./userpool";
import {CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useNavigate } from "react-router-dom";
import AWS from 'aws-sdk';

AWS.config.update({
  region: "us-east-1"
});



const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", 
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  
}));

/**
 * Renders a sign-up form.
 *
 * @returns {JSX.Element} The sign-up form component.
 */
export default function SignUp() {
  const navigate = useNavigate();

  const classes = useStyles();
  const [formValid, setFormValid] = useState(false);

  const [noSpaces, setNoSpaces] = useState(false);
  const [password, setPassword] = useState("");
  const [pwMin8, setPwMin8] = useState(false);
  const [pwLC1, setPwLC1] = useState(false);
  const [pwUC1, setPwUC1] = useState(false);
  const [pwNum1, setPwNum1] = useState(false);
  const [pwSC1, setPwSC1] = useState(false);

  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailAgain, setEmailAgain] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  // Error handling
  const [registrationCodeError, setRegistrationCodeError] = useState('');


  const validateFields = () => {
    const isPasswordGood = validatePassword();
    const isGood =
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      email === emailAgain &&
      registrationCode.length > 5 &&
      isPasswordGood &&
      password.length > 7 &&
      password === confirmPassword;
    setFormValid(true);
    return true;
  };

  const validatePassword = () => {
    if (noSpaces && pwMin8 && pwLC1 && pwUC1 && pwNum1 && pwSC1) {
    return true;
    }
    else {
    return false;
    }
  };

  


  const handleSignUp = async(event) => {
    event.preventDefault();
    if (validateFields()) {
      const attributeList = [];
          attributeList.push(
            new CognitoUserAttribute({Name: 'email', Value: email,}),
            new CognitoUserAttribute({Name: 'given_name', Value: firstName,}),
            new CognitoUserAttribute({Name: 'family_name', Value: lastName,}),
          );
          let username = email;
          const validationData = [
            { Name: 'registrationCode', Value: registrationCode }
          ];
          
       
          userpool.signUp(username, password, attributeList, validationData, (err, data) => {
            if (err) {
              console.log(err);
              if (err.code === 'UserLambdaValidationException' && err.message.includes("Invalid registration code")) {
                setRegistrationCodeError("Invalid registration code. Please try again.");
              } else {
                setRegistrationCodeError("An error occurred during sign-up. Please try again.");
              }
              return;
            }
            else {
              console.log(data);
              console.log("Sign up successful");
              setSignUpSuccess(true);
              setRegistrationCodeError('');
          }
          }
          );
    }
  };

  const handleVerificationCodeSubmit = async (event) => {
    event.preventDefault();
    const cognitoUser = new CognitoUser({Username: email, Pool: userpool});
    cognitoUser.confirmRegistration(verificationCode, true, async(err, result) => {
      if (err) {
          console.error('Error confirming sign-up:', err);
          // Handle confirmation error
      } else {
          // Confirmation successful, you can redirect or show a confirmation message
          console.log('Sign-up confirmed:', result);
          navigate("/");
      }
    });

    // Handle verification code submission
};

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setNoSpaces(value.includes(" "));
    // Check password requirements
    setPwMin8(value.length >= 8);
    setPwLC1(/[a-z]/.test(value));
    setPwUC1(/[A-Z]/.test(value));
    setPwNum1(/\d/.test(value));
    setPwSC1(/[!@#$%^&*(),.?":{}|<>]/.test(value));
  };


  

return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
            </Avatar> */}
            <Typography component="h1" variant="h5" style={{marginBottom:20}}>
                Sign up
            </Typography>
            {!signUpSuccess ? (
            <form onSubmit={handleSignUp} noValidate >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lname"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="confirmEmail"
                            label="Confirm Email Address"
                            name="confirmEmail"
                            autoComplete="confirm-email"
                            value={emailAgain}
                            onChange={(e) => setEmailAgain(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="registrationCode"
                            label="Registration Code"
                            name="registrationCode"
                            autoComplete="registration-code"
                            value={registrationCode}
                            onChange={(e) => setRegistrationCode(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={handlePasswordChange}
                            error={noSpaces}
                            helperText={noSpaces ? "Password cannot contain spaces" : ""}   
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="confirm-password"
                        />
                    </Grid>
            {/* Password progress box */}
            <Box mt={2}>
                  <Typography variant="subtitle1">New Password Requires:</Typography>
                  <Box ml={2}>
                    <FormControlLabel
                      style={{  justifyContent: 'left'}} 
                      control={<Checkbox checked={pwMin8} disabled />}
                      label="Min 8 Characters"
                    />
                    <FormControlLabel
                      style={{  justifyContent: 'left'}} 
                      control={<Checkbox checked={pwLC1} disabled />}
                      label="1 lower case letter"
                    />
                    <FormControlLabel
                      style={{  justifyContent: 'left'}} 
                      control={<Checkbox checked={pwUC1} disabled />}
                      label="1 upper case letter"
                    />
                    <FormControlLabel
                      style={{  justifyContent: 'left' }} 
                      control={<Checkbox checked={pwNum1} disabled />}
                      label="1 number"
                    />
                    <FormControlLabel
                      style={{  justifyContent: 'left' }} // Add this line to set display to block
                      control={<Checkbox checked={pwSC1} disabled />}
                      label="1 special character"
                    />
                  </Box>
                </Box>
                
                {registrationCodeError && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {registrationCodeError}
                  </div>
                )}

                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign Up
                </Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link href="/" variant="body2">
                            Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </form>
            ) : (
              <form onSubmit={handleVerificationCodeSubmit} noValidate >
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="verificationCode"
                          label="Verification Code"
                          name="verificationCode"
                          autoComplete="verification-code"
                          helperText="Please check your email for the verification code."
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                      />
                  </Grid>
              </Grid>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
              >
                  Verify
              </Button>
          </form>
            )}
        </div>

    </Container>
);
}
