import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Dashboard from './Dashboard';


import SignUpForm from './Signup-new';
import Login from './Login';

function App() {

 
return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signup" exact element={<SignUpForm />} />
          <Route path="/Dashboard" element={ <Dashboard/>} />
          <Route path="/" element={<Login/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;