import { CognitoUserPool } from 'amazon-cognito-identity-js';


// Dev account
// const poolData = {
//   UserPoolId: 'us-east-1_4bJHVWiv8',
//   ClientId: '7i942tsjok6n1ibtacprn1patj',
//  };

//Prod account
const poolData = {
  UserPoolId: 'us-east-1_JcNQ0Ua6D',
  ClientId: '74k490tr7ua2l114j8nk1fgcc4',
 };


export default new CognitoUserPool(poolData);